<template>
  <aside
    aria-label="Advertisement"
    class="size-full overflow-hidden shadow-card"
    :class="`rounded-${rounded} ${widthFit ? 'flex justify-center' : ''}`"
  >
    <div
      v-show="!isAdBannerLoaded"
      v-lazy-container="{
        selector: 'img',
        error: `https://fakeimg.pl/${props.originalWidth}x${props.originalHeight}/3b3b3b/3b3b3b`,
        loading: `https://fakeimg.pl/${props.originalWidth}x${props.originalHeight}/3b3b3b/3b3b3b`,
      }"
      class="relative overflow-hidden"
      :class="`${widthFit ? '' : 'size-full'} rounded-${rounded}`"
    >
      <img
        :data-src="src"
        class="hidden h-auto w-full object-contain md:block"
        alt="Promotional banner"
      />
      <img
        :data-src="srcMobile || src"
        class="h-auto w-full object-contain md:hidden"
        alt="Promotional banner (mobile)"
      />
      <SpinnerLoader v-if="!src" class="absolute inset-0" />
    </div>

    <!-- AdsTerra banner container -->
    <div
      v-show="isAdBannerLoaded"
      :key="retryCount"
      ref="adContainer"
      class="overflow-hidden"
      :class="`rounded-${rounded} ${widthFit ? '' : 'size-full'}`"
    ></div>
  </aside>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, onUnmounted } from 'vue';
import SpinnerLoader from './SpinnerLoader.vue';

const props = withDefaults(
  defineProps<{
    src?: string;
    srcMobile?: string;
    originalWidth: number;
    originalHeight: number;
    width?: string;
    height?: string;
    adKey: string;
    rounded?: string;
    maxRetries?: number;
    widthFit?: boolean;
  }>(),
  {
    rounded: 'xl',
    widthFit: false,
    src: '',
    srcMobile: '',
    maxRetries: 5,
    height: undefined,
    width: undefined,
  },
);

const isAdBannerLoaded = ref(false);
const adContainer = ref<HTMLElement | null>(null);
const retryCount = ref(0);

const loadAd = () => {
  if (!adContainer.value) return;

  // Reset the loaded state
  isAdBannerLoaded.value = false;

  // Set up the atOptions
  const atOptions = {
    key: props.adKey,
    format: 'iframe',
    height: 10,
    width: 10,
    params: {},
  };

  // Create the script element
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

  // Create another script to load the ad
  const loadScript = document.createElement('script');
  loadScript.type = 'text/javascript';
  loadScript.src = `//www.topcpmcreativeformat.com/${props.adKey}/invoke.js`;

  // Append the scripts to the adContainer
  adContainer.value.innerHTML = '';
  adContainer.value.appendChild(script);
  adContainer.value.appendChild(loadScript);

  // Set up a listener to check when the ad is loaded
  const checkAdLoaded = setInterval(() => {
    if (adContainer.value && adContainer.value.querySelector('iframe')) {
      isAdBannerLoaded.value = true;
      adjustAdSize();
      clearInterval(checkAdLoaded);
    }
  }, 100);

  // Clear the interval after 1 second if the ad hasn't loaded
  setTimeout(async () => {
    clearInterval(checkAdLoaded);
    if (!isAdBannerLoaded.value) {
      await retryLoadAd();
    }
  }, 1000);
};

const retryLoadAd = async () => {
  if (retryCount.value < props.maxRetries) {
    retryCount.value++; // Retry ad load
    await nextTick(() => {
      loadAd();
    });
  } else {
    console.error(`Failed to load ad after ${props.maxRetries} attempts`);
  }
};

const adjustAdSize = () => {
  if (adContainer.value) {
    const iframe = adContainer.value?.querySelector('iframe');
    if (!iframe) return;

    const applyStyles = () => {
      iframe.style.width = props.width ?? '100%';
      iframe.style.maxWidth = props.width ?? props.originalWidth.toString();
      iframe.style.aspectRatio = `${props.originalWidth / props.originalHeight}/1`;
      iframe.style.height = props.height ?? props.originalHeight.toString();

      const iframeDoc =
        iframe?.contentDocument || iframe?.contentWindow?.document;
      if (!iframeDoc) return;

      const img = iframeDoc.querySelector('img');
      if (!img) return;

      img.style.maxWidth = props.width ?? props.originalWidth.toString();
      img.style.maxHeight = props.height ?? props.originalHeight.toString();
      img.style.width = props.width ?? '100%';
      img.style.height = props.height ?? '100%';
    };

    // Aplicar estilos inmediatamente
    applyStyles();

    // Crear un MutationObserver para detectar cambios en el iframe
    const observer = new MutationObserver(() => {
      requestAnimationFrame(applyStyles);
    });

    observer.observe(iframe, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // Sistema de reintentos
    let retries = 0;
    const maxRetries = 5;
    const retryInterval = 100;

    const retryAdjustSize = () => {
      if (retries < maxRetries) {
        setTimeout(() => {
          applyStyles();
          retries++;
          retryAdjustSize();
        }, retryInterval);
      }
    };

    retryAdjustSize();

    // Limpiar el observer después de un tiempo
    setTimeout(() => {
      observer.disconnect();
    }, 1000);
  }
};

onMounted(async () => {
  await nextTick();
  loadAd();
  window.addEventListener('resize', adjustAdSize);
  // Llamar a adjustAdSize después de un breve retraso para asegurarse de que el iframe esté cargado
  setTimeout(adjustAdSize, 500);
});

onUnmounted(() => {
  window.removeEventListener('resize', adjustAdSize);
});
</script>
